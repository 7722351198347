* {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  list-style: none;
  border: 0
}

body {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  /* overflow-y: scroll; */
}

@layer utilities {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(13, 13, 14, 0.5);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: rgba(13, 13, 14, 0.5);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Estilização apenas para navegadores Firefox */
  ::-moz-scrollbar {
    width: 8px;
  }

  ::-moz-scrollbar-thumb {
    background-color: rgba(13, 13, 14, 0.5);
    border-radius: 4px;
  }

  ::-moz-scrollbar-thumb:horizontal {
    background-color: rgba(13, 13, 14, 0.5);
  }

  ::-moz-scrollbar-track {
    background-color: transparent;
  }

}


@tailwind base;
@tailwind components;
@tailwind utilities;